import React from 'react';

import Dialog, { DialogProps } from './Dialog';

export interface DialogContainerProps {
  dialog?: DialogProps | null;
}

const DialogContainer: React.FC<DialogContainerProps> = ({ dialog }) => {
  return (
    <>
      {!!dialog && (
        <Dialog
          title={dialog.title}
          description={dialog.description}
          callers={dialog.callers}
          data={dialog.data}
        />
      )}
    </>
  );
};

export default DialogContainer;
