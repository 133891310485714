import React, { useCallback } from 'react';
import Button from '@material-ui/core/Button';
import DialogContainer from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { useDialog } from '../../../hooks/dialog';

export interface DialogProps {
  title: string;
  description?: string;
  callers?: DialogCaller[];
  data?: any;
}

export interface DialogCaller {
  response: string;
  label?: string;
  handleCall(data: any): void;
  color?: 'primary' | 'secondary';
}

export interface DialogResponse {
  response: string;
  data?: any;
}

const Dialog: React.FC<DialogProps> = ({
  title,
  description = '',
  callers = [],
  data = {},
}) => {
  const { closeDialog } = useDialog();

  const onCloseDialog = useCallback((): void => {
    closeDialog();
  }, [closeDialog]);

  return (
    <DialogContainer
      open
      onClose={onCloseDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {callers?.map((caller) => (
          <Button
            key={caller.response}
            onClick={
              () => caller.handleCall({ response: caller.response, data })
              // eslint-disable-next-line react/jsx-curly-newline
            }
            color={caller.color || 'primary'}
            variant="outlined"
          >
            {caller.label || caller.response}
          </Button>
        ))}
      </DialogActions>
    </DialogContainer>
  );
};

export default Dialog;
