import light from './light';
import dark from './dark';
import rose from './rose';

const themes = {
  light,
  dark,
  rose,
};

export default themes.light;
