import React from 'react';

import ReactLoading, { LoadingType } from 'react-loading';

import { Container } from './style';

export interface LoadingProps {
  title?: string;
  description?: string;
  type?: LoadingType;
}

const Loading: React.FC<LoadingProps> = ({
  title,
  description,
  type = 'spin',
}) => {
  return (
    <Container>
      <section>
        <ReactLoading type={type} />
        <h1>{title}</h1>
        <h2>{description}</h2>
      </section>
    </Container>
  );
};

export default Loading;
