import styled from 'styled-components';

export const Container = styled.div`
  position: fixed; /* Sit on top of the page content */
  display: block; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
  z-index: 2;

  section {
    div {
      margin: 0 auto 10px;
    }

    h1 {
      color: #ff9000;
    }

    position: absolute;
    top: 50%;
    left: 50%;
    text-align: center;
    color: ${(props) => props.theme.colors.textHelper};
    font-size: 70%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
  }
`;
