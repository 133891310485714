import React from 'react';

import { Container } from './styles';

interface TooltipProps {
  title: string;
  bgColor?: string;
  color?: string;
}

const Tooltip: React.FC<TooltipProps> = ({
  title,
  bgColor = '#ff9000',
  color = '#312e38',
  children,
}) => {
  return (
    <Container color={color} bgColor={bgColor}>
      {children}
      <span>{title}</span>
    </Container>
  );
};

export default Tooltip;
