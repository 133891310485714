import React, { createContext, useContext, useCallback, useState } from 'react';

import Loading, { LoadingProps } from '../components/Loading';

interface LoadingContextData {
  enableLoading(props: LoadingProps): void;
  disableLoading(): void;
}

const LoadingContext = createContext<LoadingContextData>(
  {} as LoadingContextData,
);

function useLoading(): LoadingContextData {
  const context = useContext(LoadingContext);

  if (!context) {
    throw new Error('useLoading must be used whithin a LoadingProvider');
  }

  return context;
}

const LoadingProvider: React.FC = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [loadingProps, setLoadingProps] = useState<LoadingProps>(
    {} as LoadingProps,
  );

  const enableLoading = useCallback((props: LoadingProps) => {
    setLoading(true);
    setLoadingProps(props);
  }, []);

  const disableLoading = useCallback(() => {
    setTimeout(() => {
      setLoading(false);
      setLoadingProps({});
    }, 1000);
  }, []);

  return (
    <LoadingContext.Provider value={{ enableLoading, disableLoading }}>
      {loading && <Loading {...loadingProps} />}
      {children}
    </LoadingContext.Provider>
  );
};

export { LoadingProvider, useLoading };
