import React from 'react';

import { LoadingProvider } from './loading';
import { AuthProvider } from './auth';
import { ToastProvider } from './toast';
import { DialogProvider } from './dialog';

const AppProvider: React.FC = ({ children }) => (
  <LoadingProvider>
    <AuthProvider>
      <DialogProvider>
        <ToastProvider>{children}</ToastProvider>
      </DialogProvider>
    </AuthProvider>
  </LoadingProvider>
);

export default AppProvider;
