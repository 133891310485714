import styled from 'styled-components';
import { shade } from 'polished';

import backgroundImg from '../../assets/sign-in-background.png';

export const Container = styled.div`
  height: 100vh;
  display: flex;
  align-items: stretch;
  overflow-x: hidden;

  section {
    display: flex;
    flex-direction: column;
    place-content: center;
    width: 100%;
    max-width: 700px;
    align-items: center;

    form {
      margin: 80px 0;
      width: 340px;
      text-align: center;

      h1 {
        margin-bottom: 24px;
      }

      a {
        color: ${(props) => props.theme.colors.feature};
        text-decoration: none;
        margin-top: 24px;
        display: block;
        transition: color 0.2;

        &:hover {
          color: ${(props) => shade(0.2, props.theme.colors.feature)};
        }
      }
    }

    > a {
      color: ${(props) => props.theme.colors.highlight};
      text-decoration: none;
      margin-top: 24px;
      display: block;
      transition: color 0.2;
      display: flex;
      align-items: center;

      &:hover {
        color: ${(props) => shade(0.2, props.theme.colors.highlight)};
      }

      svg {
        margin-right: 16px;
      }
    }
  }
`;

export const Background = styled.div`
  flex: 1;
  background: url(${backgroundImg}) no-repeat center;
  background-size: cover;
`;
