export default {
  title: 'dark',

  colors: {
    background: '#fff',
    text: '#666360',
    textHelper: '#fff',
    highlight: '#ff9000',
    label: '#999591',
    border: '#3e3b47',
    header: '#28262e',
    feature: '#999591',
    disabled: '#666360',
    selected: '#232129',
    error: '#c53030',
  },
};
