import styled, { keyframes } from 'styled-components';

interface ContainerProps {
  direction: 'right' | 'left' | 'up' | 'down';
  time: number;
}

const directionTypes = {
  right: keyframes`
          from {
            opacity: 0;
            transform: translateX(-50px);
          }
          to {
            opacity: 1;
            transform: translateX(0);
          }
          `,
  left: keyframes`
        from {
          opacity: 0;
          transform: translateX(50px);
        }
        to {
          opacity: 1;
          transform: translateX(0);
        }
        `,
  up: keyframes`
      from {
        opacity: 0;
        transform: translateY(50px);
      }
      to {
        opacity: 1;
        transform: translateX(0);
      }
      `,
  down: keyframes`
        from {
          opacity: 0;
          transform: translateY(-50px);
        }
        to {
          opacity: 1;
          transform: translateX(0);
        }
        `,
};

export const Container = styled.section<ContainerProps>`
  animation: ${(props) => directionTypes[props.direction || 'left']}
    ${(props) => props.time}s;
`;
