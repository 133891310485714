import React from 'react';

import { Container } from './styles';

interface FadeContainerProps {
  direction?: 'right' | 'left' | 'up' | 'down';
  time?: number;
  style?: React.CSSProperties | undefined;
}

const FadeContainer: React.FC<FadeContainerProps> = ({
  direction = 'right',
  time = 1,
  style = undefined,
  children,
}) => (
  <Container direction={direction} time={time} style={style}>
    {children}
  </Container>
);

export default FadeContainer;
