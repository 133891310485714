import React, { useRef, useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { FiLogIn, FiMail, FiLock } from 'react-icons/fi';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';
import { useLoading } from '../../hooks/loading';

import getValidationErrors from '../../utils/getValidationErrors';

import { Input, Button, FadeContainer } from '../../components';

import logoImg from '../../assets/logo.svg';
import { Container, Background } from './styles';

interface SignInFormData {
  email: string;
  password: string;
}

const Signin: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();

  const { signIn } = useAuth();
  const { addToast } = useToast();
  const { enableLoading, disableLoading } = useLoading();

  const handleSubmit = useCallback(
    async (data: SignInFormData) => {
      try {
        enableLoading({
          title: 'Carregando',
          description: 'Processando as informações',
        });

        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          email: Yup.string()
            .required('Email é obrigatório')
            .email('Insira um email válido'),
          password: Yup.string().required('Senha é obrigatória'),
        });
        await schema.validate(data, {
          abortEarly: false,
        });

        await signIn({ email: data.email, password: data.password });

        history.push('/dashboard');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const formErrors = getValidationErrors(err);
          formRef.current?.setErrors(formErrors);
          return;
        }
        addToast({ type: 'error', title: err.message });
      } finally {
        disableLoading();
      }
    },
    [signIn, addToast, enableLoading, disableLoading, history],
  );

  return (
    <Container>
      <FadeContainer time={2}>
        <img src={logoImg} alt="logo" />
        <Form
          ref={formRef}
          initialData={{
            email: 'contato@weblife.com.br',
            password: '12345',
          }}
          onSubmit={handleSubmit}
        >
          <h1>Faça seu Logon</h1>

          <Input icon={FiMail} name="email" placeholder="Email" />

          <Input
            icon={FiLock}
            name="password"
            placeholder="Senha"
            type="password"
          />

          <Button type="submit">Entrar</Button>

          <Link to="/forgot-password">Esqueci minha senha</Link>
        </Form>

        <Link to="/signup">
          <FiLogIn />
          Criar Conta
        </Link>
      </FadeContainer>
      <Background />
    </Container>
  );
};

export default Signin;
