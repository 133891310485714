import React, { useCallback, useRef, ChangeEvent } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { FiArrowLeft, FiMail, FiLock, FiUser, FiCamera } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import getValidationErrors from '../../utils/getValidationErrors';

import api from '../../services/api';

import { Input, Button } from '../../components';

import { Container, Content, AvatarInput } from './styles';

import { useToast } from '../../hooks/toast';
import { useAuth } from '../../hooks/auth';
import { useLoading } from '../../hooks/loading';

interface ProfileFormData {
  name: string;
  email: string;
  old_password?: string;
  password?: string;
  password_confirmation?: string;
}

const Profile: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  const { user, updateUser } = useAuth();
  const { addToast } = useToast();
  const { enableLoading, disableLoading } = useLoading();

  const history = useHistory();

  const handleSubmit = useCallback(
    async (data: ProfileFormData) => {
      try {
        enableLoading({
          title: 'Carregando',
          description: 'Processando as informações',
        });

        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
          email: Yup.string()
            .required('Email obrigatório')
            .email('Digite um email válido'),

          old_password: Yup.string().when('password_confirmation', {
            is: (val) => !!val.length,
            then: Yup.string().required('Campo obrigatório'),
            otherwise: Yup.string(),
          }),

          password: Yup.string().when('old_password', {
            is: (val) => !!val.length,
            then: Yup.string()
              .required('Campo obrigatório')
              .min(6, 'Digite pelo menos 6 caractéres'),
            otherwise: Yup.string(),
          }),

          password_confirmation: Yup.string().oneOf(
            [Yup.ref('password'), null],
            'As senhas não coincidem',
          ),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const {
          name,
          email,
          old_password,
          password,
          password_confirmation,
        } = data;

        const formData = {
          name,
          email,
          ...(old_password
            ? {
                old_password,
                password,
                password_confirmation,
              }
            : {}),
        };

        const response = await api.put('/profile', formData);

        updateUser(response.data);

        history.push('/dashboard');

        addToast({
          type: 'success',
          title: 'Dados atualizados com sucesso!',
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }
        addToast({
          type: 'error',
          title: 'Falha ao atualizar os dados.',
        });
      } finally {
        disableLoading();
      }
    },
    [addToast, history, updateUser, enableLoading, disableLoading],
  );

  const handleAvatarChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        enableLoading({
          title: 'Carregando',
          description: 'O upload está sendo realizado',
        });

        const data = new FormData();

        data.append('avatar', e.target.files[0]);

        api
          .patch('/users/avatar', data)
          .then((response) => {
            updateUser(response.data);

            addToast({
              type: 'success',
              title: 'A imagem foi atualizada.',
            });
          })
          .catch(() => {
            addToast({
              type: 'error',
              title: 'Não foi possível atualizar o avatar.',
              description: 'Verifique o formato e tamanho do arquivo.',
            });
          })
          .finally(() => {
            disableLoading();
          });
      }
    },
    [addToast, updateUser, enableLoading, disableLoading],
  );

  return (
    <Container>
      <header>
        <div>
          <Link to="/dashboard">
            <FiArrowLeft />
          </Link>
        </div>
      </header>
      <Content>
        <Form
          ref={formRef}
          onSubmit={handleSubmit}
          initialData={{ name: user.name, email: user.email }}
        >
          <AvatarInput>
            <img src={user.avatar_url} alt={user.name} />
            <label htmlFor="avatar">
              <FiCamera />
              <input
                type="file"
                id="avatar"
                onChange={handleAvatarChange}
                accept=".png,.jpg"
              />
            </label>
          </AvatarInput>

          <h1>Meu Perfil</h1>

          <Input icon={FiUser} name="name" placeholder="Nome" />
          <Input icon={FiMail} name="email" placeholder="Email" />

          <Input
            containerStyle={{ marginTop: 24 }}
            icon={FiLock}
            name="old_password"
            placeholder="Senha atual"
            type="password"
          />

          <Input
            icon={FiLock}
            name="password"
            placeholder="Nova Senha"
            type="password"
          />

          <Input
            icon={FiLock}
            name="password_confirmation"
            placeholder="Confirmar Senha"
            type="password"
          />

          <Button type="submit">Confirmar mudanças</Button>
        </Form>
      </Content>
    </Container>
  );
};

export default Profile;
