import React, { useContext, createContext, useCallback, useState } from 'react';

import {
  DialogProps,
  DialogCaller,
  DialogResponse as DialogResponseInterface,
} from '../components/DialogContainer/Dialog';

import DialogContainer from '../components/DialogContainer';

interface DialogContextData {
  showDialog(data: Omit<DialogProps, 'openState'>): void;
  closeDialog(): void;
}

export type DialogResponse = DialogResponseInterface;

const DialogContext = createContext<DialogContextData>({} as DialogContextData);

function useDialog(): DialogContextData {
  const context = useContext(DialogContext);

  if (!context) throw new Error('useAuth must be used whithin a AuthProvider');

  return context;
}

const DialogProvider: React.FC = ({ children }) => {
  const [dialog, setDialog] = useState<DialogProps | null>(null);

  const showDialog = useCallback(
    ({
      title,
      description = '',
      callers,
      data = null,
    }: Omit<DialogProps, 'openState'>): void => {
      const callersMap: DialogCaller[] = [];
      if (callers) {
        callers.map((caller) =>
          callersMap.push({
            response: caller.response,
            handleCall: caller.handleCall,
            label: caller.label || caller.response,
            color: caller.color || 'primary',
          }),
        );
      }
      setDialog({
        title,
        description,
        callers,
        data,
      });
    },
    [],
  );

  const closeDialog = useCallback(() => {
    setDialog(null);
  }, []);

  return (
    <DialogContext.Provider value={{ showDialog, closeDialog }}>
      {children}
      <DialogContainer dialog={dialog} />
    </DialogContext.Provider>
  );
};

export { useDialog, DialogProvider };
