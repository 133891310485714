import React, { useCallback, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { FiArrowLeft, FiMail, FiLock, FiUser } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import getValidationErrors from '../../utils/getValidationErrors';

import api from '../../services/api';

import logoImg from '../../assets/logo.svg';

import { Input, Button, FadeContainer } from '../../components';

import { Container, Background } from './styles';

import { useToast } from '../../hooks/toast';
import { useLoading } from '../../hooks/loading';

interface SignUpFormData {
  name: string;
  email: string;
  password: string;
  repassword?: string;
}

const Signup: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  const { addToast } = useToast();
  const { enableLoading, disableLoading } = useLoading();

  const history = useHistory();

  const handleSubmit = useCallback(
    async (data: SignUpFormData) => {
      try {
        enableLoading({
          title: 'Carregando',
          description: 'Processando as informações',
        });

        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
          email: Yup.string()
            .required('Email obrigatório')
            .email('Digite um email válido'),
          password: Yup.string().min(6, 'No mínimo 6 dígitos'),
          repassword: Yup.string().oneOf(
            [Yup.ref('password'), null],
            'As senhas não coincidem',
          ),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await api.post('/users', data);

        history.push('/');

        addToast({
          type: 'success',
          title: 'Cadastro efetuado com sucesso!',
          description: 'Você já pode fazer seu logon no GoBarber',
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }
        addToast({
          type: 'error',
          title: err.message,
        });
      } finally {
        disableLoading();
      }
    },
    [addToast, history, enableLoading, disableLoading],
  );

  return (
    <Container>
      <Background />

      <FadeContainer time={2} direction="left">
        <img src={logoImg} alt="logo" />
        <Form ref={formRef} onSubmit={handleSubmit}>
          <h1>Faça seu cadastro</h1>

          <Input icon={FiUser} name="name" placeholder="Nome" />
          <Input icon={FiMail} name="email" placeholder="Email" />

          <Input
            icon={FiLock}
            name="password"
            placeholder="Senha"
            type="password"
          />

          <Input
            icon={FiLock}
            name="repassword"
            placeholder="Confirmar Senha"
            type="password"
          />

          <Button type="submit">Cadastrar</Button>
        </Form>

        <Link to="/">
          <FiArrowLeft />
          Voltar para logon
        </Link>
      </FadeContainer>
    </Container>
  );
};

export default Signup;
